.background_white {
  background-color: white;
}

.background_web_dark_grey {
  background-color: #222731;
}

/* Base Styles*/
html body {
  margin: 0;
  padding: 0;
  background-color: #2A2B33;
  color: black;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1rem;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input[type=text] {
  border: none;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 6px;
  width: 100%;
}

/* Links - exception for footer below */
a:link {
  color: #009CDE;
  text-decoration:none;
}

a:visited {
  color: #009CDE;
  text-decoration: none;
}

a:hover {
  color: #009CDE;
  text-decoration: underline;
}
  
a:active {
  color: #009CDE;
  text-decoration: none;
}

.admin_link, .admin_link a:link, .admin_link a:visited, .admin_link a:hover, .admin_link a:active{
  color:#ffffff;
  text-decoration:none!important; 
}


/* Tables */
table {
  width: 100%;
  border-spacing: 0;
}

table tr {
  padding: 0;
  margin: 0;
}

table tr td,
table tr th {
  padding: 0;
  margin: 0;
  text-align: left;
}

/* Typography */
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Brandon Grotesque", Calibri, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  display: inline;
}

.loader {
  margin: 0 auto;
  border: 3px solid #d6d6d6;
  border-radius: 50%;
  border-top: 3px solid #002A3A;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.invisible {
  display: none;
}

.visible {
  display: unset;
}

.transparent {
  opacity: 0;
}

.padding_bottom_0 {
  padding-bottom: 0 !important;
}

.padding_bottom_5 {
  padding-bottom: 5px !important;
}

.padding_bottom_10 {
  padding-bottom: 10px !important;
}

.padding_bottom_20 {
  padding-bottom: 20px !important;
}

.padding_bottom_30 {
  padding-bottom: 30px !important;
}

.padding_bottom_40 {
  padding-bottom: 40px !important;
}

.padding_top_0 {
  padding-top: 0px !important;
}

.padding_top_5 {
  padding-top: 5px !important;
}

.padding_top_10 {
  padding-top: 10px !important;
}

.padding_top_20 {
  padding-top: 20px !important;
}

.padding_top_30 {
  padding-top: 30px !important;
}

.padding_top_40 {
  padding-top: 40px !important;
}

.padding_right_10 {
  padding-right: 10px !important;
}

.padding_right_20 {
    padding-right: 20px !important;
  }

.padding_right_30 {
    padding-right: 30px !important;
 }

.bold_text {
  font-weight: bold;
}

.italic_text {
  font-style: italic;
}

.segment {
  margin: 0 auto;
  max-width: 1024px;
}

.admin_segment{
    margin: 0 auto;
    width:100%;
}

.segment_padding {
  padding: 0 10px;
}

.row {
  width: 100%;
  margin: 0 auto;
  min-width: 800px;
  *zoom: 1;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.row_padding {
  padding: 20px 0;
}

.outcontainer {
  width: 100%;
  height: auto;
  overflow: auto;
}

.image_full_width {
  display: block;
  width: 100%;
  height: auto;
}

.apoc_customer_portal .logout {
  float: right;
  text-decoration: underline;
  font-family: "Brandon Grotesque", Calibri, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  color: #63666A;
  text-transform: uppercase;
  cursor: pointer;
}

.apoc_customer_portal .my_reports,
.apoc_customer_portal .my_messages,
.apoc_customer_portal .my_poc {
  display: block;
}

.apoc_customer_portal a.tab {
  padding: 10px 20px;
  font-family: "Brandon Grotesque", Calibri, Helvetica, Arial, sans-serif;
  font-weight: bold;
  display: inline-block;
  background-color: #D9D9D6;
  color: #222731;
  border: none;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.apoc_customer_portal a.tab_selected {
  color: white;
  background-color: black;
}

.apoc_customer_portal .tabs_content {
  border-bottom: 1px solid black;
}

.apoc_customer_portal .tabs_content > div {
  display: inline-block;
  margin-right:4px;
}

.user_hosted_links_list {
  padding: 20px 60px;
}

.user_hosted_links_list div {
  color: white;
  padding: 10px 10px;
  margin-bottom: 20px;
}

.user_hosted_links_list div a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.user_hosted_links_list div.odd {
  background-color: #004F71;
}

.user_hosted_links_list div.even {
  background-color: #70CACB;
}

.my_reports a, .my_messages span, .my_poc span {
  color: #009CDE;
  text-decoration: underline;
}

.my_messages .message_detail .my_poc .poc_detail {
  padding-left: 0px;
}

.dataTables_wrapper {
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgba(30, 42, 56, 0.5);
  font-size: 0.9rem;
}

.dataTables_wrapper th, .dataTables_wrapper td {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5rem;
}

.dataTables_wrapper th {
  text-align: left;
  background-color: white;
}

.dataTables_wrapper tbody tr:hover,
.dataTables_wrapper tbody tr:hover > .sorting_1 {
  background-color: #bce1f4 !important;
}

.dataTables_wrapper table.dataTable.hover tbody tr.child {
  background: white !important;
}

.dataTables_wrapper table.dataTable.hover tbody tr table tr:hover {
  background: none !important;
}

.dataTables_wrapper .dataTables_length {
  padding-top: 0.3em;
  padding-left: 10px;
  color: white;
  font-size: 0.7rem;
}

.dataTables_wrapper .dataTables_info {
  padding-top: 0.7em;
  padding-right: 10px;
  padding-left: 40px;
  color: white;
  font-size: 0.7rem;
  float: right;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.4em;
  padding-bottom: 0.6em;
  float: right;
  font-size: 0.7rem;
  line-height: 1em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: none !important;
  min-width: 1em !important;
  padding: 0.4em 0.75em !important;
  color: white !important;
  border-radius: unset !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button img {
  display: inline-block;
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: none !important;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  border: none !important;
  background: #002A3A;
  text-decoration: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  opacity: 0.5 !important;
}

.dataTables_wrapper .details-control {
  cursor: pointer;
}

.container_search {
  float: right;
  margin-bottom:5px;
}

.search_box {
  border: 1px #002A3A solid;
}

input.search_text {
  width: 200px;
  margin: 0;
}

.search_button {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

.apoc_login_portal .form_container {
  font-family: "Brandon Grotesque", Calibri, Helvetica, Arial, sans-serif;
  width: 50%;
  margin: 0 auto;
}

.apoc_login_portal .form_container p {
  color: #009CDE;
  font-size: 1.5rem;
}

.apoc_login_portal .form_container h1 {
  font-size: 3.25rem;
  line-height: 1.125em;
  color: #009CDE;
  text-align: center;
}

.apoc_login_portal .form_container label {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.apoc_login_portal input[type=text], .apoc_login_portal input[type=password] {
  border: 1px #222731 solid;
  background-color: white;
  font-size: 1.25rem;
  padding: 10px;
  width: 100%;
}

.apoc_login_portal input[type=submit], .apoc_login_portal input[type=button]  {
  border: none;
  background-color: #009CDE;
  color: white;
  font-size: 1.25rem;
  padding: 10px;
  width: 200px;
  text-transform: uppercase;
}

.apoc_login_portal .formresult {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: firebrick;
}

.apoc_login_portal .formresult_success {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: green;
}

.apoc_login_portal .forget_password {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #009CDE;
  text-decoration: none;
}

.apoc_login_portal .form_container .password_reminder p,
.apoc_login_portal .form_container .password_confirmation_reminder p {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: firebrick;
  font-style: italic;
}

.apoc_login_portal .form_container .psw_verified {
  display: none !important;
}

.apoc_login_portal .form_container .psw_not_verified {
  color: firebrick;
}

.apoc_text {
  color: black;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 14px;
}

/* Abbott Header */
.segment_inside {
  margin: 0 auto;
}

.container_grey {
  background-color: #222731;
}

.apoc_logo {
  float: left;
  vertical-align: middle;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 27px;
}

.apoc_title {
  float: right;
  vertical-align: middle;
  color: #ffffff;
  font-family: "Brandon Grotesque", calibri;
  font-size: 14px;
  padding-top: 21px;
  padding-right: 27px;
}

/* Abbott footer */
.apocfooter {
  font-family: Georgia, Times, "Times New Roman", serif;
  color: #ffffff; 
  font-size: 10px;
  text-decoration:none;
  min-width: 800px;
  margin: 0 auto;
}

.apoclegal a:link { color: #ffffff; text-decoration:none; }
.apoclegal a:visited { color: #ffffff; text-decoration:none; }
.apoclegal a:hover { color: #009CDE; text-decoration:underline; }
.apoclegal a:active { color: #009CDE; text-decoration:none; }
  
/**************************************/
/*         confirmation msg           */
/**************************************/
.container_confirm {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 42, 56, 0.5);
  z-index: 999;
  cursor: pointer;
}

.confirm {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(84, 86, 90, 0.05);
  width: 500px;
  padding: 20px 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0 8px 16px 0 rgba(30, 42, 56, 0.5);
}

.confirm .buttons {
  text-align: center;
  padding-top: 10px;
}

.confirm span {
  font-family: "Brandon Grotesque", Calibri, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: #009CDE;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  text-align: center;
  width: 80px;
  cursor: pointer;
}

.confirm .message {
  text-align: left;
  padding-bottom: 10px;
}


.poc_detail {
  text-align: center;
  margin:30px;
}

.poc_detail_item_container {
  float:right;
  text-align:left;
  padding-left:20px;
}

.poc_detail_item, .poc_detail_item_sm {
  margin-bottom:3px;
}
.poc_detail_item_sm {
  font-size:14px;
}

.poc_image_container {
  float:left;
  
}

.poc_image {
  width:150px;
}


.poc_detail_label {
  
}


.content_details {
    min-height: 400px;
}

.grid-row-even{
  background-color: rgb(247, 246, 246);

}
.grid-row-odd{
  
}