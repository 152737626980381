
.admin_container{
    min-height:800px;
}


.admin_link, .admin_link a:link .logout a:link {
    color: white;
    text-decoration: underline;
    cursor: pointer;
    padding-right:15px;
    padding-left:5px;
    text-transform: uppercase;
}

.welcomeHeader, .welcomeheader.a:link{
    
    margin: 0 auto;
    color:white;
}

.padding_left_5{
    padding-left:5px;
}

.padding_left_10{
    padding-left:10px;
}

.padding_right_10{
    padding-right:10px;
}
.padding_right_5{
    padding-right:5px;
}